<div class="position-relative default-color-text" *ngIf="!isForm">
    <div class="form-group">
        <label class="f-neutral-3" [for]="fieldId">{{ fieldLabel }}</label>
        <input
            *ngIf="
                !isPhoneFiled &&
                fieldType !== 'password' &&
                fieldType !== 'number' &&
                !fieldHaveDecimalMarker &&
                !isForm
            "
            [disabled]="isDisabled"
            autocomplete="off"
            mask="{{ fieldMask }}"
            placeholder="{{ fieldPlaceholder }}"
            [dropSpecialCharacters]="fieldDropMask"
            #textField="ngModel"
            [type]="fieldType"
            [name]="fieldName"
            class="default-field custom-input input-group"
            [ngClass]="{
                'upper-case': fieldName === 'name-register-field-name',
                fieldSearch: fieldName === 'item-search-field-name',
                isRequired: 'has-validation'
            }"
            [id]="fieldId"
            [required]="isRequired"
            (input)="sendValue()"
            [(ngModel)]="fieldNgModel"
        />
        <input
            *ngIf="
                !isPhoneFiled &&
                fieldType !== 'password' &&
                fieldHaveDecimalMarker
            "
            [disabled]="isDisabled"
            autocomplete="off"
            mask="{{ fieldMask }}"
            [decimalMarker]="','"
            placeholder="{{ fieldPlaceholder }}"
            [dropSpecialCharacters]="fieldDropMask"
            #textField="ngModel"
            [type]="fieldType"
            [name]="fieldName"
            class="default-field custom-input"
            [id]="fieldId"
            [required]="isRequired"
            (input)="sendValue()"
            [(ngModel)]="fieldNgModel"
            inputmode="decimal"
        />
        <input
            *ngIf="!isPhoneFiled && fieldType === 'password'"
            [disabled]="isDisabled"
            placeholder="{{ fieldPlaceholder }}"
            autocomplete="off"
            mask="{{ fieldMask }}"
            [dropSpecialCharacters]="fieldDropMask"
            #textField="ngModel"
            inputmode="numeric"
            [type]="fieldType"
            [name]="fieldName"
            class="default-field"
            [id]="fieldId"
            [required]="isRequired"
            (input)="sendValue()"
            [(ngModel)]="fieldNgModel"
        />
        <input
            *ngIf="isPhoneFiled"
            prefix=""
            placeholder="{{ fieldPlaceholder }}"
            [disabled]="isDisabled"
            autocomplete="off"
            mask="{{ fieldMask }}"
            [dropSpecialCharacters]="fieldDropMask"
            #textField="ngModel"
            [type]="fieldType"
            [name]="fieldName"
            class="default-field"
            [ngClass]="{ 'password-field': showPassword === false }"
            [id]="fieldId"
            [required]="isRequired"
            (input)="sendValue()"
            [(ngModel)]="fieldNgModel"
        />
        <input
            class="default-field h-48px"
            [id]="fieldId"
            type="number"
            id="quantity"
            name="quantity"
            min="1"
            max="100"
            (input)="sendValue()"
            [(ngModel)]="fieldNgModel"
            *ngIf="fieldType === 'number'"
        />

        <div
            class="field-icon-sect pointer-section"
            *ngIf="isPassword && !isDisabled"
        >
            <i
                ngClass="{{
                    fieldType === 'password'
                        ? 'bi bi-eye-slash'
                        : 'bi bi-eye-fill'
                }}"
                (click)="updatePasswordEyes(!showPassword)"
            >
            </i>
        </div>
        <div class="field-icon-search" *ngIf="isSearch">
            <i class="bi bi-search"></i>
        </div>
        <div class="field-icon-x" *ngIf="isSearchCleanIcon">
            <i class="bi bi-x-circle-fill" (click)="clearInputSearch()"></i>
        </div>
    </div>
</div>

<div *ngIf="isForm">
    <ng-container [formGroup]="formName">
        <label class="f-neutral-3" [for]="fieldId">{{ fieldLabel }}</label>
        <input
            [formControlName]="fieldName"
            [disabled]="isDisabled"
            autocomplete="off"
            mask="{{ fieldMask }}"
            placeholder="{{ fieldPlaceholder }}"
            [dropSpecialCharacters]="fieldDropMask"
            [type]="fieldType"
            [name]="fieldName"
            class="default-field custom-input input-group"
            [ngClass]="{
                'upper-case': fieldName === 'name-register-field-name',
                fieldSearch: fieldName === 'item-search-field-name',
                isRequired: 'has-validation'
            }"
            [id]="fieldId"
            [required]="isRequired"
            [(ngModel)]="fieldNgModel"
        />
    </ng-container>
</div>
