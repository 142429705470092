import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../../shared/interfaces/user/user.interface';
import { sessionStorageToken } from '../../shared/tokens/sessionstorage.token';
import { localStorageToken } from '../../shared/tokens/localstorage.token';

@Injectable({
    providedIn: 'root',
})
export class HomeService {
    constructor(
        @Inject(localStorageToken) private localStorage: Storage,
        private http: HttpClient
    ) {}

    baseUri = environment.HOME_BASE;

    getUserDetails(): Observable<User> {
        return this.http.get<User>(`${this.baseUri}/users/details`).pipe(tap());
    }

    storageUserDetails(user: User) {
        this.localStorage.setItem('user', JSON.stringify(user));
    }

    getStorageUserDetails(): User {
        let user = JSON.parse(this.localStorage.getItem('user')!);
        return user;
    }

    hasClaim(claimName: string): boolean {
        return this.getStorageUserDetails().claims.includes(claimName);
    }
}
