<div class="scroll-to-top" [ngClass]="{ 'show-scrollTop': windowScrolled }">
    <button
        type="button"
        class="square-button-default"
        data-toggle="button"
        aria-pressed="true"
        (click)="scrollToTop()"
    >
        <i class="bi bi-arrow-up"></i>
    </button>
</div>
