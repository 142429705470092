import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-user-claims-table',
  templateUrl: './user-claims-table.component.html',
  styleUrls: ['./user-claims-table.component.scss']
})
export class UserClaimsTableComponent implements OnInit {

  @Input() items: { groupName: string, claims: any[] }[] = [];

  ngOnInit(): void { }
}
