import {
    HTTP_INTERCEPTORS,
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { Router } from '@angular/router';
import { TENANT } from 'src/app/multi-tenant-web-portal/shared/interfaces/tenant/tenant.interface';

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
    constructor(private router: Router, private authService: AuthService) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const authToken = this.authService.getToken();
        const tenantIdHeader = { 'TenantId': `${TENANT.ID}` };
        let defaultReq = req.clone({
            setHeaders: tenantIdHeader,
        });
        console.log('AuthTokenInterceptor', defaultReq);
        console.log('TenanTid', tenantIdHeader);
        if (authToken) {
            defaultReq = defaultReq.clone({
                setHeaders: {
                    ...tenantIdHeader,
                    Authorization: `Bearer ${authToken}`,
                },
            });
        }

        return next.handle(defaultReq).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 401 && !error.url?.includes('tokens')) {
                    this.router.navigate(['/login']);
                }
                return throwError(() => error);
            })
        );
    }
}

export const AuthInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthTokenInterceptor,
    multi: true,
};
