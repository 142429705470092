import { Routes } from '@angular/router';
import { authGuard } from '../../core/guards/auth.guard';
import { hasClaim } from '../../core/guards/role.guard';

export const ENGAGEMENTS_ROUTES: Routes = [
    {
        path: 'create',
        canActivate: [authGuard, hasClaim('CanReadPolicy')],
        loadComponent: () =>
            import(
                '../../features/engagements/pages/engagement-form/engagement-form.component'
            ).then((m) => m.EngagementFormComponent),
    },
    {
        path: 'edit/:id',
        canActivate: [authGuard, hasClaim('CanReadPolicy')],
        loadComponent: () =>
            import(
                '../../features/engagements/pages/engagement-form/engagement-form.component'
            ).then((m) => m.EngagementFormComponent),
    },
];
