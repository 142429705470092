<div class="dropdown">
    <button
        class="btn btn-default-secondary dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-bs-auto-close="true"
        type="button"
    >
        {{ placeholder }}
    </button>
    <ul class="dropdown-menu">
        <div *ngFor="let item of items">
            <li>
                <a class="dropdown-item" href="#" (click)="selectItem(item)">
                    {{ item.tenantName }}</a
                >
            </li>
        </div>
    </ul>
</div>
