import { Routes } from '@angular/router';
import { authGuard } from '../../core/guards/auth.guard';

export const BASE_ROUTES: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    {
        path: 'login',
        loadChildren: () =>
            import('../../pages/login-page/login-page.module').then(
                (m) => m.LoginPageModule
            ),
    },
    {
        path: 'login/forgot-password',
        loadChildren: () =>
            import(
                '../../pages/login-page/pages/forgot-password-page/forgot-password-page.module'
            ).then((m) => m.ForgotPasswordPageModule),
    },
    {
        path: 'login/forgot-password-step2',
        loadChildren: () =>
            import(
                '../../pages/login-page/pages/forgot-password-step2-page/forgot-password-step2-page.module'
            ).then((m) => m.ForgotPasswordStep2PageModule),
    },
    {
        path: 'login/reset-password',
        loadChildren: () =>
            import(
                '../../pages/login-page/pages/reset-password-page/reset-password-page.module'
            ).then((m) => m.ResetPasswordPageModule),
    },
    {
        path: 'login/two-factor',
        loadChildren: () =>
            import(
                '../../pages/login-page/pages/two-factor-page/two-factor-page.module'
            ).then((m) => m.TwoFactorPageModule),
    },
    {
        path: 'login/email',
        loadChildren: () =>
            import(
                '../../pages/login-page/pages/confirm-email-page/confirm-email-page.module'
            ).then((m) => m.ConfirmEmailPageModule),
    },
    {
        path: 'login/email/confirm',
        loadChildren: () =>
            import(
                '../../pages/login-page/pages/confirm-email-page/confirm-email-page.module'
            ).then((m) => m.ConfirmEmailPageModule),
    },
    {
        path: 'reset-password-success',
        loadChildren: () =>
            import(
                '../../pages/login-page/pages/reset-password-success-page/reset-password-success-page.module'
            ).then((m) => m.ResetPasswordSuccessPageModule),
    },
    {
        path: 'select-place',
        loadChildren: () =>
            import(
                '../../pages/login-page/pages/login-select-place-page/login-select-place-page.module'
            ).then((m) => m.LoginSelectPlacePageModule),
    },
    {
        path: 'contact-us',
        loadChildren: () =>
            import('../../pages/contact-us/contact-us.module').then(
                (m) => m.ContactUsModule
            ),
    },
    {
        path: 'not-found',
        loadChildren: () =>
            import('../../pages/not-found-page/not-found-page.module').then(
                (m) => m.NotFoundPageModule
            ),
    },
    {
        path: 'dashboard',
        canActivate: [authGuard],
        loadChildren: () =>
            import('../../pages/dashboard-page/dashboard-page.module').then(
                (m) => m.DashboardPageModule
            ),
    },
    { path: '**', redirectTo: '/not-found', pathMatch: 'full' },
];
