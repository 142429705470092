<div class="form-check">
    <input
        class="form-check-input"
        type="checkbox"
        [checked]="checked"
        (input)="sendValue($event)"
        [id]="id"
    />
    <label [for]="id" class="form-check-label"
        ><span class="f-14px fw-400 lh-20px">{{ title }} </span>
        <span
            class="f-link f-14px fw-400 lh-20px"
            *ngIf="externalLinkText"
            (click)="goExternalLink()"
        >
            {{ externalLinkText }}</span
        ></label
    >
</div>
