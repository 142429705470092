<input
    type="file"
    class="input-group"
    placeholder="Nenhum arquivo selecionado"
    accept="image/png, image/jpeg"
    (change)="registerOnChange($event)"
/>
<span class="f-neutral-4 f-12px lh-18px"
    >Máximo de 512kb, Formato quadrado, aparece no app</span
>
