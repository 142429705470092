import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
    @Input() selectedItem?: any;
    @Input() clearable: boolean = false;
    @Input() placeHolder: string = 'Busque o item desejado';
    @Input() notFoundText: string = 'Item não encontrado';
    @Input({ required: true }) items$?: Observable<any[] | null> | null;
    @Input() disabled: boolean = false;
    @Input() loading: boolean = false;
    @Input({ required: true }) value: string = '';
    @Input({ required: true }) label: string = '';
    @Output() selectedItemEmitter = new EventEmitter<any>();
    @Output() onChangeEmitter = new EventEmitter<any>();

    constructor() {}

    selectItem(item: any) {
        this.selectedItemEmitter.emit(item);
    }

    onChange(event: any) {
        this.onChangeEmitter.emit(event);
    }
}
