<ng-container *transloco="let t; prefix: 'languages'">
    <div class="language-selector">
        <select
            id="language-select"
            data-width="fit"
            (click)="changeLanguage($event)"
        >
            <option [value]="'pt-BR'">
                🇧🇷&emsp;
                {{ t("lang-br") }}
            </option>
            <option [value]="'en'">
                🇺🇸&emsp;
                {{ t("lang-en") }}
            </option>
            <option [value]="'es'">
                🇲🇽&emsp;
                {{ t("lang-es") }}
            </option>
        </select>
    </div>
</ng-container>
