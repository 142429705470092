import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { HomeService } from '../../services/home/home.service';

@Directive({
    selector: '[appHasClaim]',
    standalone: true,
})
export class HasClaimDirective {
    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private homeService: HomeService
    ) {}

    @Input('appHasClaim') set hasClaim(claimValue: string) {
        if (
            this.homeService.getStorageUserDetails().claims.includes(claimValue)
        ) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}
