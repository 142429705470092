@for (item of menuItems; track item.id) {
<li class="nav-item" *appHasClaim="item.claim">
    <a
        class="nav-link collapsed"
        [attr.data-bs-target]="'#' + item.id"
        data-bs-toggle="collapse"
        href="#"
    >
        <i [ngClass]="item.icon"></i><span>{{ item.title }}</span
        ><i class="bi bi-chevron-down ms-auto"></i>
    </a>
    <ul
        [attr.id]="item.id"
        class="nav-content collapse"
        data-bs-parent="#sidebar-nav"
        id="nav-items"
    >
        @for(i of item.items; track i.name) { @if(item.id !== 'reports' &&
        i.show !== false){
        <li *appHasClaim="i.claim">
            <a
                [routerLink]="i.path"
                routerLinkActive="active"
                (click)="hideSidebar()"
            >
                <span>{{ i.name }}</span>
            </a>
        </li>
        } @if(i.subitems !== undefined){
        <li class="nav-item" *appHasClaim="i.claim">
            <a
                class="nav-link collapsed"
                [attr.data-bs-target]="'#' + i.id"
                data-bs-toggle="collapse"
                href="#"
            >
                <i [ngClass]="i.icon"></i><span>{{ i.title }}</span
                ><i class="bi bi-chevron-down ms-auto"></i>
            </a>
            <ul
                [attr.id]="i.id"
                class="nav-sub-content collapse"
                data-bs-parent="#nav-items"
            >
                @for(sub of i.subitems; track sub.id) {
                <li *appHasClaim="sub.claim">
                    <a
                        [routerLink]="sub.path"
                        routerLinkActive="active"
                        (click)="hideSidebar()"
                    >
                        <span>{{ sub.name }}</span>
                    </a>
                </li>
                }
            </ul>
        </li>
        } }
    </ul>
</li>
}
