import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { HomeService } from './multi-tenant-web-portal/services/home/home.service';
import { InitService } from './multi-tenant-web-portal/services/init.service';
import { TENANT } from './multi-tenant-web-portal/shared/interfaces/tenant/tenant.interface';
import { TenantConfiguration } from './multi-tenant-web-portal/shared/interfaces/tenant/tenantConfiguration.interface';
import { User } from './multi-tenant-web-portal/shared/interfaces/user/user.interface';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    isDash: boolean = false;
    showMenu: boolean = false;
    loading = false;
    showSide = false;
    user?: User;

    iconUrl: string = '';
    @Input() showTenant: boolean = false;

    tenantConfiguration!: TenantConfiguration;
    intervalId: number | null = null;

    constructor(
        private router: Router,
        private initService: InitService,
        private homeService: HomeService
    ) {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (event.url.includes('dashboard')) {
                    this.isDash = true;
                    this.user = this.homeService.getStorageUserDetails();
                } else this.isDash = false;
            }
        });
    }

    ngOnDestroy(): void {
        if (this.intervalId) {
            clearInterval(this.intervalId);
        }
    }

    ngOnInit(): void {
        this.initializeApp();
    }

    initializeApp() {
        this.setTheme(this.initService.tenantConfigurations);
    }

    setTheme(tenantConfiguration: TenantConfiguration) {
        document.documentElement.style.setProperty(
            '--color-primary',
            tenantConfiguration.primaryColor
        );
        document.documentElement.style.setProperty(
            '--bs-primary',
            tenantConfiguration.primaryColor
        );
        document.documentElement.style.setProperty(
            '--color-secondary',
            tenantConfiguration.secondaryColor
        );
        document.documentElement.style.setProperty(
            '--color-tertiary',
            tenantConfiguration.tertiaryColor
        );
        document.documentElement.style.setProperty(
            '--color-button-text',
            tenantConfiguration.buttonTextColor
        );

        if (tenantConfiguration.imageUrl != '')
            document.documentElement.style.setProperty(
                '--image-url',
                `url('${tenantConfiguration.imageUrl}')`
            );

        this.tenantConfiguration = tenantConfiguration;

        TENANT.ID = this.tenantConfiguration.tenantId;
        TENANT.TENANT_NAME = this.tenantConfiguration.name;
        TENANT.THREE_DECIMAL_PLACES_PRECISION =
            this.tenantConfiguration.useThreeDecimalPlacesPrecision;
        TENANT.IS_SPACE_DELIVERY_ENABLED =
            this.tenantConfiguration.isSpaceDeliveryEnabled;
        TENANT.IS_RESIDUE_COLLECTION_ENABLED =
            this.tenantConfiguration.isResidueCollectionEnabled;
    }
}
