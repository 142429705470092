import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-accordion',
    templateUrl: './accordion.component.html',
    styleUrl: './accordion.component.scss',
})
export class AccordionComponent {
    @Input({ required: true }) accordionItems: any[] = [];
}
