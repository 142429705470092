import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import {
    CURRENCY_MASK_CONFIG,
    CurrencyMaskConfig,
    CurrencyMaskModule,
} from 'ng2-currency-mask';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { HasClaimDirective } from '../directives/has-claim.directive';
import { layoutComponents } from './layout-components';
import { NgxScannerQrcodeModule, LOAD_WASM } from 'ngx-scanner-qrcode';
import { TranslocoRootModule } from 'src/app/transloco-root.module';

LOAD_WASM().subscribe();

export const CUSTOM_CURRENCY_MASK_CONFIG: CurrencyMaskConfig = {
    align: 'left',
    allowNegative: false,
    decimal: ',',
    thousands: '.',
    precision: 2,
    prefix: '',
    suffix: '',
};

@NgModule({
    declarations: [layoutComponents],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaskDirective,
        NgxMaskPipe,
        NgSelectModule,
        NgbDropdownModule,
        RouterModule,
        CurrencyMaskModule,
        NgxSkeletonLoaderModule.forRoot({
            animation: 'pulse',
        }),
        HasClaimDirective,
        NgOptionHighlightModule,
        NgxScannerQrcodeModule,
        TranslocoRootModule,
    ],

    exports: layoutComponents,
    providers: [
        provideNgxMask(),
        {
            provide: CURRENCY_MASK_CONFIG,
            useValue: CUSTOM_CURRENCY_MASK_CONFIG,
        },
    ],
})
export class LayoutModule {}
