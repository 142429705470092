<div class="row d-flex flex-wrap justify-content-start p-2">
    @for(item of accordionItems; track item.id){ @if(item.items.length > 0){
    <div class="col-md-4 col-sm-12 p-2">
        <div class="accordion" id="accordionFlushExample">
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button
                        class="accordion-button collapsed f-16px lh-20px fw-500 f-neutral-1"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#' + item.id"
                        aria-expanded="true"
                        [attr.aria-controls]="item.id"
                    >
                        {{ item.name }}
                    </button>
                </h2>
                @for(i of item.items; track i.id){
                <div
                    [attr.id]="i.id"
                    class="accordion-collapse collapse"
                    formArrayName="scoreResidues"
                >
                    <div class="accordion-body">
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                [attr.id]="i.name"
                            />
                            <label class="form-check-label" [attr.for]="i.name">
                                {{ i.name }}
                            </label>
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    </div>
    } }
</div>
