import { Inject, Injectable } from '@angular/core';
import { AuthServiceInterface } from './auth.service.interface';
import {
    BehaviorSubject,
    catchError,
    map,
    Observable,
    tap,
    throwError,
} from 'rxjs';
import { LoginResponse } from '../../models/auth/login-response.interface';
import { LoginTwoFactor } from '../../models/auth/login-two-factor.interface';
import { Login } from '../../models/auth/login.interface';
import { environment } from 'src/environments/environment';
import { sessionStorageToken } from 'src/app/multi-tenant-web-portal/shared/tokens/sessionstorage.token';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { localStorageToken } from 'src/app/multi-tenant-web-portal/shared/tokens/localstorage.token';

@Injectable({
    providedIn: 'root',
})
export class AuthService implements AuthServiceInterface {
    private readonly TOKEN_NAME: string = 'token';
    private readonly baseUri = environment.TOKEN_BASE;
    private readonly loginUri = environment.LOGIN_BASE;
    private _isLoggedIn$ = new BehaviorSubject<boolean>(false);
    private isLoggedIn$ = this._isLoggedIn$.asObservable();

    constructor(
        @Inject(sessionStorageToken) private sessionStorage: Storage,
        @Inject(localStorageToken) private localStorage: Storage,
        private cookieService: CookieService,
        private readonly http: HttpClient,
        private readonly router: Router
    ) {
        this._isLoggedIn$.next(!!this.getToken());
    }

    getToken(): any {
        return this.cookieService.get(this.TOKEN_NAME);
    }

    isLoggedIn(): boolean {
        return (
            this.getToken() !== null &&
            this.getToken() !== '' &&
            this.localStorage.getItem('user') !== undefined
        );
    }

    authenticate(body: Login): Observable<LoginResponse> {
        return this.http
            .post<LoginResponse>(`${this.baseUri}/user`, body, {
                headers: {
                    'X-Consumer-Id': environment.auth_params.xConsumerId,
                },
            })
            .pipe(
                tap((response: LoginResponse) => {
                    this.cookieService.set(this.TOKEN_NAME, response.token, {
                        expires: new Date(response.expiresOn),
                        secure: true,
                        sameSite: 'Lax',
                    });
                    this._isLoggedIn$.next(true);
                })
            );
    }

    twoFactorAuthenticate(body: LoginTwoFactor): Observable<LoginResponse> {
        return this.http
            .post<LoginResponse>(`${this.baseUri}/twoFactor`, body, {
                headers: {
                    'X-Consumer-Id': environment.auth_params.xConsumerId,
                },
            })
            .pipe(
                tap((response: LoginResponse) => {
                    this.cookieService.set(this.TOKEN_NAME, response.token, {
                        expires: new Date(response.expiresOn),
                        secure: true,
                        sameSite: 'Lax',
                    });
                    this._isLoggedIn$.next(true);
                })
            );
    }

    refresh(body: string): Observable<LoginResponse> {
        return this.http.post<any>(`${this.baseUri}/refresh`, body);
    }

    logout() {
        this.sessionStorage.clear();
        this.localStorage.clear();
        if (this.cookieService.check(this.TOKEN_NAME))
            this.cookieService.delete(this.TOKEN_NAME);

        this.router.navigate(['/login']);
    }
}
