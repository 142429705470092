<div class="desktop">
    <div class="dash-card text-center" (click)="goTo(item.route)">
        <div class="square">
            <i class="{{ item.icon }} f-32px"></i>
        </div>
        <p class="m-0 f-14px f-neutral-4 pt-24px pb-24px">
            {{ item.text }}
        </p>
        <button class="btn btn-default-primary">{{ item.buttonText }}</button>
    </div>
</div>
<div class="mobile">
    <div class="row">
        <div class="col-sm-12 pt-40px">
            <button class="btn btn-default-primary" (click)="goTo(item.route)">
                {{ item.buttonText }}
                <i class="{{ item.icon }} pl-6px f-14px"></i>
            </button>
        </div>
    </div>
</div>
