<!-- <app-language-selector></app-language-selector> -->
<div *ngIf="isDash">
    <app-header-menu
        (expandedEventEmitter)="(showSide)"
        [user]="user!"
        [iconUrl]="tenantConfiguration.iconUrl"
    ></app-header-menu>
    <app-sidebar-menu></app-sidebar-menu>
</div>
<div
    [ngClass]="
        !isDash
            ? 'white-bg p-0'
            : 'white-bg sidebar-margin header-margin footer-margin'
    "
>
    <router-outlet></router-outlet>
</div>
