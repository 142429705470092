import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Profile } from 'src/app/multi-tenant-web-portal/shared/interfaces/profiles/profile.interface';

@Component({
    selector: 'app-user-card',
    templateUrl: './user-card.component.html',
    styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent implements OnInit {
    @Input() item!: Profile;
    @Output() cardClicked = new EventEmitter<any>();

    constructor(private router: Router) {}

    ngOnInit(): void {
        const storedRole = sessionStorage.getItem('role');
        if (storedRole) {
            const role = JSON.parse(storedRole);

            if (this.item.id === role.id) {
                this.item.isClicked = true;
            }
        }
    }

    handlePermission() {
        this.cardClicked.emit(this.item);
    }
}
