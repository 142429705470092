import { Routes } from '@angular/router';
import { authGuard } from '../../core/guards/auth.guard';
import { hasClaim } from '../../core/guards/role.guard';

export const TENANTS_ROUTES: Routes = [
    {
        path: 'advantage',
        canActivate: [authGuard, hasClaim('CanReadTenantAdvantage')],
        loadChildren: () =>
            import(
                '../../pages/tenant-advantages-page/tenant-advantages-page-routing.module'
            ).then((m) => m.TenantAdvantagesPageRoutingModule),
    },
];
