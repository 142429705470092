<div *ngFor="let group of items" class="row pt-32px">
  <div class="col-md-12 col-sm-12">
    <strong class="f-neutral-3 f-20px">{{ group.groupName }}</strong>
    <table>
      <thead>
        <tr>
          <th class="f-neutral-3 f-16px">Permissão</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let claim of group.claims">
          <td class="f-neutral-3">{{ claim.description }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>