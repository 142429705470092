import { Routes } from '@angular/router';
import { authGuard } from '../../core/guards/auth.guard';
import { hasClaim } from '../../core/guards/role.guard';

export const PEOPLE_ROUTES: Routes = [
    {
        path: 'participants',
        canActivate: [authGuard, hasClaim('CanReadPerson')],
        loadChildren: () =>
            import(
                '../../pages/people-page/participant-query/participant-query.module'
            ).then((m) => m.ParticipantQueryModule),
    },
    {
        path: 'participant/create',
        canActivate: [authGuard, hasClaim('CanCreatePerson')],
        loadChildren: () =>
            import(
                '../../pages/people-page/participant-form/participant-form.module'
            ).then((m) => m.ParticipantFormModule),
    },
    {
        path: 'participant/edit/:id',
        canActivate: [authGuard, hasClaim('CanCreatePerson')],
        loadChildren: () =>
            import(
                '../../pages/people-page/participant-form/participant-form.module'
            ).then((m) => m.ParticipantFormModule),
    },
    {
        path: 'participant/details/:id',
        canActivate: [authGuard, hasClaim('CanReadPerson')],
        loadChildren: () =>
            import(
                '../../pages/people-page/participant-detail/participant-detail.module'
            ).then((m) => m.ParticipantDetailModule),
    },
    {
        path: 'users',
        canActivate: [authGuard, hasClaim('CanReadPerson')],
        loadChildren: () =>
            import('../../pages/people-page/user-query/user-query.module').then(
                (m) => m.UserQueryModule
            ),
    },
    {
        path: 'user/create',
        canActivate: [authGuard, hasClaim('CanCreatePerson')],
        loadChildren: () =>
            import('../../pages/people-page/user-form/user-form.module').then(
                (m) => m.UserFormModule
            ),
    },
    {
        path: 'user/edit/:id',
        canActivate: [authGuard, hasClaim('CanCreatePerson')],
        loadChildren: () =>
            import(
                '../../pages/people-page/user-edit-form/user-edit-form.module'
            ).then((m) => m.UserEditFormModule),
    },
    {
        path: 'user/details/:id',
        canActivate: [authGuard, hasClaim('CanReadPerson')],
        loadChildren: () =>
            import(
                '../../pages/people-page/user-detail/user-detail.module'
            ).then((m) => m.UserDetailModule),
    },
    {
        path: 'message/timeline/:id',
        canActivate: [authGuard],
        loadChildren: () =>
            import(
                '../../pages/people-page/message-timeline-page/message-timeline-page.module'
            ).then((m) => m.MessageTimelinePageModule),
    },
];
