import { Component } from '@angular/core';
import { LanguageService } from 'src/app/multi-tenant-web-portal/core/services/language/language.service';

@Component({
    selector: 'app-language-selector',
    templateUrl: './language-selector.component.html',
    styleUrl: './language-selector.component.scss',
})
export class LanguageSelectorComponent {
    currentLang: string;
    languages = this.languageService.getAvailableLanguages();

    constructor(private languageService: LanguageService) {
        this.currentLang = this.languageService.getCurrentLanguage();
        console.log(this.currentLang);
    }

    changeLanguage(lang: any) {
        this.languageService.setLanguage(lang.target.value);
        this.currentLang = lang.target.value;
    }
}
