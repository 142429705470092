import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent {

  @Input() items: any[] = [];
  @Input() placeholder?: string = '';

  @Output() selectedItem = new EventEmitter<any>();

  selectItem(item: any) {
    this.selectedItem.emit(item);
    this.placeholder = item.tenantName
  }
}
