import {
    HashLocationStrategy,
    LocationStrategy,
    registerLocaleData,
} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localePtBr from '@angular/common/locales/pt';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, RouterOutlet } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablesModule } from 'angular-datatables';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptorProvider } from './multi-tenant-web-portal/core/interceptors/auth/auth-token.interceptor';
import { InitService } from './multi-tenant-web-portal/services/init.service';
import { LayoutModule } from './multi-tenant-web-portal/shared/layouts/layout.module';
import { WINDOW_PROVIDERS } from './multi-tenant-web-portal/shared/tokens/window.token';
import { TranslocoRootModule } from './transloco-root.module';
import { CookieService } from 'ngx-cookie-service';

registerLocaleData(localePtBr);

function initFactory(initService: InitService) {
    return () => initService.init();
}
@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        LayoutModule,
        HttpClientModule,
        RouterModule,
        RouterOutlet,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        NgSelectModule,
        NgOptionHighlightModule,
        NgbModule,
        DataTablesModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
        }),
        TranslocoRootModule,
    ],
    providers: [
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        AuthInterceptorProvider,
        [
            {
                provide: APP_INITIALIZER,
                useFactory: initFactory,
                deps: [InitService],
                multi: true,
            },
        ],
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        WINDOW_PROVIDERS,
        CookieService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
