import { Component, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-grid-actions',
    styleUrl: './grid-actions.component.scss',
    templateUrl: './grid-actions.component.html',
})
export class GridActionsComponent implements OnInit {
    @Output()
    emitter = new Subject<GridButtonEventType>();

    @Input()
    data: any = {};

    @Input()
    cmd?: string;

    @Input()
    extras: any[] = [];

    @Input() hideEdit: boolean = false;
    @Input() hideDetail: boolean = false;
    @Input() hideRemove: boolean = false;

    constructor(private route: Router) {}

    ngOnInit(): void {}

    edit() {
        this.emitter.next({
            cmd: 'edit',
            data: this.data,
        });
    }

    remove() {
        this.emitter.next({
            cmd: 'remove',
            data: this.data,
        });
    }

    details() {
        this.emitter.next({
            cmd: 'detail',
            data: this.data,
        });
    }

    extraFunction(command: any) {
        this.emitter.next({
            cmd: `${command}`,
            data: this.data,
        });
    }

    ngOnDestroy() {
        this.emitter.unsubscribe();
    }
}

export interface GridButtonEventType {
    cmd: string;
    data: any;
}
