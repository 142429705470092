import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ScoreResidue } from 'src/app/multi-tenant-web-portal/shared/interfaces/scores/scoreResidue.interface';

@Component({
  selector: 'app-edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls: ['./edit-modal.component.scss']
})
export class EditModalComponent implements OnInit {
  itemCounterField = {
    fieldName: 'item-counter-field-name',
    fieldId: 'item-counter-field-id',
    fieldType: 'decimal',
    fieldNgModel: '0',
    fieldHaveMask: true,
    fieldMask: 'separator',
    fieldDecimalMarker: false
  };

  amount?: number;
  residue!: ScoreResidue;
  reward!: string;
  isUnits: boolean = true;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    if (this.residue) {
      this.itemCounterField.fieldNgModel = this.residue.amount.toString();

      if (this.residue.scoreResidueCategory?.unitType === 2)
        this.isUnits = true;
    }

    if (this.reward) {
      this.itemCounterField.fieldNgModel = this.reward;
    }
  }

  closeModal() {
    this.activeModal.close(this.amount);
  }

  amountItems(evt: string) {
    let value = evt.toString().replace(',', '.');
    this.amount = Number(value);
  }

}
