import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import {
    NgxScannerQrcodeComponent,
    ScannerQRCodeConfig,
    ScannerQRCodeResult,
    ScannerQRCodeSelectedFiles,
} from 'ngx-scanner-qrcode';

@Component({
    selector: 'app-qr-code',
    templateUrl: './qr-code.component.html',
    styleUrl: './qr-code.component.scss',
})
export class QrCodeComponent implements AfterViewInit, OnDestroy {
    public config: ScannerQRCodeConfig = {
        constraints: {
            video: {
                width: window.innerWidth,
            },
            audio: false,
        },
        isBeep: false,
        vibrate: 100,
    };

    public qrCodeResult: ScannerQRCodeSelectedFiles[] = [];
    public quality = 100;
    @ViewChild('action') action!: NgxScannerQrcodeComponent;
    @Output() valueEmitter = new EventEmitter<string>();

    ngAfterViewInit(): void {
        this.action.isReady.subscribe((res: any) => {
            this.handle(this.action, 'start');
        });
    }
    ngOnDestroy(): void {
        this.action.stop();
    }
    public handle(action: any, fn: string): void {
        const playDeviceFacingBack = (devices: any[]) => {
            // front camera or back camera check here!
            const device = devices.find(
                (f) =>
                    /back|trás|rear|traseira|environment|ambiente/gi.test(
                        f.label
                    ) ?? devices.pop()
            ); // Default Back Facing Camera
            action.playDevice(device ? device.deviceId : devices[0].deviceId);
        };

        if (fn === 'start') {
            action[fn](playDeviceFacingBack).subscribe(
                (r: any) => console.log(fn, r),
                alert
            );
        } else {
            action[fn]().subscribe((r: any) => console.log(fn, r), alert);
        }
    }

    public onEvent(e: ScannerQRCodeResult[], action?: any): void {
        console.log(e[0].value);
        e && action && action.pause();
        this.valueEmitter.emit(e[0].value);
    }
}
