import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { HomeService } from '../../services/home/home.service';
import { AuthService } from '../services/auth/auth.service';
import { PlaceService } from '../../features/places/services/place.service';

export const authGuard: CanActivateFn = (route, state) => {
    return inject(AuthService).isLoggedIn() ||
        (inject(HomeService)
            .getStorageUserDetails()
            ?.claims?.includes('CanSelectPreferredPlaceAfterLogin') &&
            inject(PlaceService).getStoredPlace() !== null) // todo: paliativo
        ? true
        : inject(Router).createUrlTree(['/login']);
};
