import { Injectable } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    availableLangs = [
        { code: 'en', label: 'Inglês' },
        { code: 'es', label: 'Espanhol' },
        { code: 'pt-BR', label: 'Português do Brasil' },
    ];

    constructor(private translocoService: TranslocoService) {}

    setLanguage(lang: string) {
        this.translocoService.setActiveLang(lang);
    }

    getCurrentLanguage(): string {
        return this.translocoService.getActiveLang();
    }

    getAvailableLanguages() {
        return this.availableLangs;
    }
}
