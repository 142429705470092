import { Component, Input } from '@angular/core';

@Component({
    selector: 'loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent {
    @Input() loading: boolean = false;
    @Input() count = 1;
    @Input() appearance: any = 'line';
    @Input() height: any;
    @Input() width: any;
}
