import { Component, EventEmitter, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-file-upload',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FileUploadComponent,
    multi: true
  }],
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss'
})
export class FileUploadComponent implements ControlValueAccessor {

  onChange: (fn: any) => void = () => { };
  onTouched: () => void = () => { };
  private file: File | null = null;

  @Output() imageEmitter = new EventEmitter<string>();

  constructor() {
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  writeValue(value: null) {
    this.file = null;
  }

  async registerOnChange(fn: any) {
    this.onChange = fn;
    this.file = fn.target.files[0];
    try {
      const base64String = await this.fileToBase64(this.file!);
      this.imageEmitter.emit(base64String);
    } catch (error) {
      console.error('Error converting file to Base64:', error);
    }
  }

  registerOnTouched(fn: Function) {
  }

  fileToBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result as string;
        resolve(base64String.split(',')[1]); // Extract the Base64-encoded data part
      };

      reader.onerror = error => {
        reject(error);
      };

      reader.readAsDataURL(file);
    });
  }
}
