import { Routes } from '@angular/router';
import { authGuard } from '../../core/guards/auth.guard';
import { hasClaim } from '../../core/guards/role.guard';

export const REWARDS_ROUTES: Routes = [
    {
        path: '',
        canActivate: [authGuard, hasClaim('CanReadReward')],
        loadChildren: () =>
            import(
                '../../pages/rewards-page/reward-query/reward-query.module'
            ).then((m) => m.RewardQueryModule),
    },
    {
        path: 'create',
        canActivate: [authGuard, hasClaim('CanCreateReward')],
        loadChildren: () =>
            import(
                '../../pages/rewards-page/reward-form/reward-form.module'
            ).then((m) => m.RewardFormModule),
    },
    {
        path: 'details/:id',
        canActivate: [authGuard, hasClaim('CanReadReward')],
        loadChildren: () =>
            import(
                '../../pages/rewards-page/reward-detail/reward-detail.module'
            ).then((m) => m.RewardDetailModule),
    },
    {
        path: 'edit/:id',
        canActivate: [authGuard, hasClaim('CanCreateReward')],
        loadChildren: () =>
            import(
                '../../pages/rewards-page/reward-edit-form/reward-edit-form.module'
            ).then((m) => m.RewardEditFormModule),
    },
    {
        path: 'categories',
        canActivate: [authGuard, hasClaim('CanReadRewardCategory')],
        loadChildren: () =>
            import(
                '../../features/rewards/rewards-page.module'
            ).then((m) => m.RewardsPageModule),
    },
    {
        path: 'categories/create',
        canActivate: [authGuard, hasClaim('CanCreateRewardCategory')],
        loadChildren: () =>
            import(
                '../../pages/rewards-page/reward-category/reward-category-form/reward-category-form.module'
            ).then((m) => m.RewardCategoryFormModule),
    },
    {
        path: 'categories/details/:id',
        canActivate: [authGuard, hasClaim('CanReadRewardCategory')],
        loadChildren: () =>
            import(
                '../../pages/rewards-page/reward-category/reward-category-detail/reward-category-detail.module'
            ).then((m) => m.RewardCategoryDetailModule),
    },
    {
        path: 'categories/edit/:id',
        canActivate: [authGuard, hasClaim('CanCreateRewardCategory')],
        loadChildren: () =>
            import(
                '../../pages/rewards-page/reward-category/reward-category-form/reward-category-form.module'
            ).then((m) => m.RewardCategoryFormModule),
    },
    {
        path: 'coupons/:rewardId',
        canActivate: [authGuard],
        loadComponent: () =>
            import(
                '../../pages/rewards-page/coupon-query/coupon-query.component'
            ).then((c) => c.CouponQueryComponent),
    },
    {
        path: 'coupons/create/:rewardId',
        canActivate: [authGuard],
        loadComponent: () =>
            import(
                '../../pages/rewards-page/coupon-form/coupon-form.component'
            ).then((c) => c.CouponFormComponent),
    },
];
