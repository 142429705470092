import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TenantConfiguration } from '../../shared/interfaces/tenant/tenantConfiguration.interface';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  baseUri = environment.TENANT_BASE;

  constructor(private http: HttpClient) { }

  getTenantConfigurationsByDomain(domain: string): Observable<TenantConfiguration> {
    return this.http.get<TenantConfiguration>(`${this.baseUri}/configurations/${domain}`).pipe(
      tap()
    )
  }
}
