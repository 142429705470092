<ng-select
    [items]="items$ | async"
    bindValue="{{ value }}"
    bindLabel="{{ label }}"
    placeholder="{{ placeHolder }}"
    notFoundText="{{ notFoundText }}"
    appendTo="body"
    [disabled]="disabled"
    [(ngModel)]="selectedItem"
    [clearable]="clearable"
    (change)="selectItem($event)"
    [loading]="loading || items$ === undefined"
    (ngModelChange)="onChange($event)"
>
</ng-select>
