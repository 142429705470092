import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DashOption } from 'src/app/multi-tenant-web-portal/shared/interfaces/dashboard/dashboard';

@Component({
  selector: 'app-dash-card',
  templateUrl: './dash-card.component.html',
  styleUrls: ['./dash-card.component.scss']
})
export class DashCardComponent {

  @Input() item!: DashOption;

  constructor(private router: Router) { }

  goTo(route: string) {
    this.router.navigateByUrl(route)
  }

}
