<div class="user-card" [ngClass]="{ clicked: item.isClicked }">
    <input
        class="user-card"
        type="radio"
        name="options"
        autocomplete="off"
        (click)="handlePermission()"
        [checked]="item.isClicked"
    />
    <strong class="neutral-level-3 f-16px pl-16px">{{ item.name }}</strong>
</div>
