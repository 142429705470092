import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'app-checkbox',
    templateUrl: './checkbox.component.html',
    styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxComponent implements OnInit {
    @Input() title = 'Input';
    @Input() id = 'input-id';
    @Input() value = false;
    @Input() externalLink: any;
    @Input() externalLinkText: any;
    @Input() checked: boolean = false;

    @Output() emitedValue = new EventEmitter<any>();

    constructor() {}

    ngOnInit() {}

    goExternalLink() {
        window.open(this.externalLink, '_blank');
    }

    sendValue(event: any) {
        this.value = !this.value;
        this.emitedValue.emit(this.value);
    }
}
