<div class="stepper-wrapper">
    @for(step of nameStep; track step; let index = $index){
    <div
        class="stepper-item"
        [ngClass]="{
            completed: activeStep > index || activeStep === nameStep.length - 1
        }"
    >
        <div class="step-counter">{{ index + 1 }}</div>
        <div class="step-name f-16px f-neutral-3">{{ nameStep[index] }}</div>
    </div>
    }
</div>
