import { Component, EventEmitter, Input, Output } from '@angular/core';
import { User } from '../../../interfaces/user/user.interface';

@Component({
    selector: 'app-header-menu',
    templateUrl: './header-menu.component.html',
    styleUrl: './header-menu.component.scss',
})
export class HeaderMenuComponent {
    @Input() iconUrl: string = '';
    @Input() user?: User;
    isExpanded: boolean = false;
    @Output() expandedEventEmitter = new EventEmitter<boolean>();

    show() {
        window.document.body.classList.toggle('toggle-sidebar');
    }
}
