<div class="footer-position container">
    <div class="row footer p-3">
        <div class="col col-md-4 justify-content-space-around justify-content-md-between mb-10px">
            <button type="button" class="f-16px btn-tertiary-danger" (click)="clear()" *ngIf="showClear">
                <i class="bi bi-trash"></i>
                Limpar dados
            </button>
        </div>
        <div class="col col-md-2 offset-md-4 justify-content-space-around justify-content-md-between mb-10px">
            <button class="btn-default-tertiary" (click)="goBackStep()" *ngIf="showBack" type="button">
                Voltar
            </button>
        </div>
        <div class="col col-md-2 continue-button mb-10px">
            <button class="btn btn-default-primary" (click)="goNextStep()" [disabled]="disabled" type="button">
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" *ngIf="loading"></span>
                {{ nextStepText }}
            </button>
        </div>
    </div>
</div>
