<td ngbDropdown container="body" class="overflow-hidden btn-grid">
    <button type="button" class="btn btn-default-tertiary" ngbDropdownToggle>
        <i class="bi bi-three-dots-vertical"></i></button>
    <div ngbDropdownMenu class="box">

        @if(!hideEdit){
        <button ngbDropdownItem class="btn-grid-actions-default" (click)="edit()"><i class="bi bi-pencil-square"></i>
            Editar</button>
        }

        @if(!hideDetail){
        <button ngbDropdownItem class="btn-grid-actions-default" (click)="details()"><i class="bi bi-eye"></i>
            Detalhes</button>
        }

        @if(!hideRemove){
        <button ngbDropdownItem class="btn-grid-actions-default" (click)="remove()"><i class="bi bi-trash"></i>
            Remover</button>
        }

        @if(extras.length > 0 && extras !== undefined)
        {
            <div class="dropdown-divider"></div>
        }

        @for(extra of extras; track extra){
                <button ngbDropdownItem class="btn-grid-actions-default" *appHasClaim="extra.claimName"
                (click)="extraFunction(extra.command)"><i ngClass="{{ extra.icon }}"></i>
                {{ extra.buttonName }}</button>
        }
    </div>
</td>
