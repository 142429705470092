import { inject, Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivateFn,
    Router,
    RouterStateSnapshot,
} from '@angular/router';
import { HomeService } from '../../services/home/home.service';
import { AuthService } from '../services/auth/auth.service';

export function hasClaim(role: string): CanActivateFn {
    return (ars: ActivatedRouteSnapshot, rss: RouterStateSnapshot) => {
        return inject(AuthService).isLoggedIn() &&
            inject(HomeService).getStorageUserDetails().claims.includes(role)
            ? true
            : inject(Router).createUrlTree(['/dashboard/today']);
    };
}
