<div class="row container pt-16px pb-24px justify-content-center">
    <div class="col">
        <strong class="f-16px f-neutral-3">Editar quantidade:</strong>
        <p class="f-neutral-4 f-14px m-0">Digite a quantidade desejada</p>
        @if(isUnits){
        <app-text-field [fieldName]="itemCounterField.fieldName" [fieldId]="itemCounterField.fieldId"
            [fieldType]="itemCounterField.fieldType" [fieldId]="itemCounterField.fieldId"
            [fieldNgModel]="itemCounterField.fieldNgModel" [fieldHaveMask]="itemCounterField.fieldHaveMask"
            [fieldNgModel]="itemCounterField.fieldNgModel"
            [fieldHaveDecimalMarker]="itemCounterField.fieldDecimalMarker"
            (value)="amountItems($event.toString())"></app-text-field>
        }
        @else{
        <input currencyMask class="form-group default-field mt-24px" type="text" inputmode="decimal"
            [(ngModel)]="amount" placeholder="0,000" [value]="amount" />
        }
        <div class="pt-24px">
            <button class="btn btn-default-primary" (click)="closeModal()" [disabled]="!amount">
                Salvar alterações
            </button>
        </div>
    </div>
</div>