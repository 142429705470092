import { Component, Inject, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/multi-tenant-web-portal/core/services/auth/auth.service';
import { AuthServiceInterface } from 'src/app/multi-tenant-web-portal/core/services/auth/auth.service.interface';

@Component({
    selector: 'app-sidebar-menu',
    templateUrl: './sidebar-menu.component.html',
    styleUrl: './sidebar-menu.component.scss',
})
export class SidebarMenuComponent implements OnInit {
    constructor(
        @Inject(AuthService) private authService: AuthServiceInterface
    ) {}

    ngOnInit(): void {
        this.onscroll(window.document, this.navbarlinksActive);
    }

    logout() {
        this.authService.logout();
    }

    select = (el: string, all: boolean = false): any | any[] => {
        el = el.trim();
        if (all) {
            return [window.document.querySelectorAll(el)];
        } else {
            return document.querySelector(el);
        }
    };

    navbarlinks: NodeListOf<HTMLElement> = this.select(
        '#sidebar .scrollto',
        true
    );

    navbarlinksActive = (): void => {
        const position: number = window.scrollY + 200;
        this.navbarlinks.forEach((navbarlink: any) => {
            if (!navbarlink.hash) return;
            const section: HTMLElement | null = this.select(navbarlink.hash);
            if (!section) return;
            if (
                position >= section.offsetTop &&
                position <= section.offsetTop + section.offsetHeight
            ) {
                navbarlink.classList.add('active');
            } else {
                navbarlink.classList.remove('active');
            }
        });
    };

    onscroll = (el: any, listener: EventListener) => {
        el.addEventListener('scroll', listener);
    };

    hideSidebar() {
        window.document.body.classList.toggle('toggle-sidebar');
    }
}
