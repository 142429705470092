import { Component, Input } from '@angular/core';

@Component({
    selector: 'stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent {
    @Input() activeStep: number = 0;
    @Input() nameStep: any[] = [];

    isNextStep(step: number): boolean {
        return step === this.activeStep + 1;
    }
}
