import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { SelectListItem } from 'src/app/multi-tenant-web-portal/shared/interfaces/shared/selectListItem.interface';
import { sessionStorageToken } from 'src/app/multi-tenant-web-portal/shared/tokens/sessionstorage.token';
import { environment } from 'src/environments/environment';
import { ResponseAPI } from '../../mobile-app/models/question.interface';
import { PlaceDeliveryReport, PlaceDeliveryReportRequest } from '../models/place-delivery-report.interface';
import { City, Place } from '../models/place.interface';
import { PlaceRequest } from '../models/placeRequest.interface';
import { State } from '../models/state.interface';

@Injectable({
    providedIn: 'root',
})
export class PlaceService {
    baseUri = environment.PLACE_BASE;

    constructor(
        @Inject(sessionStorageToken) private storageSession: Storage,
        private http: HttpClient        
    ) {}

    getPlaces(onlyPublic: boolean, locked: boolean): Observable<Place[]> {
        return this.http
            .get<Place[]>(`${this.baseUri}/${onlyPublic}/${locked}`)
            .pipe(tap());
    }

    getById(id: string): Observable<Place> {
        return this.http.get<Place>(`${this.baseUri}/${id}`).pipe(tap());
    }

    getPlacesWithLocalOperator(): Observable<Place[]> {
        return this.http
            .get<Place[]>(`${this.baseUri}/localOperator`)
            .pipe(tap());
    }

    storePlace(place: Place) {
        localStorage.setItem('place', JSON.stringify(place));
    }

    getStoredPlace(): Place {
        let place = JSON.parse(localStorage.getItem('place')!);
        return place;
    }

    getStates(): Observable<State[]> {
        return this.http.get<State[]>(`${this.baseUri}/states`).pipe(tap());
    }

    getCities(): Observable<City[]> {
        return this.http.get<City[]>(`${this.baseUri}/cities`).pipe(tap());
    }

    getCitiesByState(state: string): Observable<City[]> {
        return this.http
            .get<City[]>(`${this.baseUri}/state/${state}/cities`);
    }

    getPlacesByState(state: string): Observable<Place[]> {
        return this.http
            .get<Place[]>(`${this.baseUri}/state/${state}/places`);
    }

    create(req: PlaceRequest): Observable<any> {
        return this.http.post<any>(`${this.baseUri}`, req).pipe(tap());
    }

    update(req: PlaceRequest): Observable<any> {
        return this.http.put<any>(`${this.baseUri}`, req).pipe(tap());
    }

    delete(id: string): Observable<any> {
        return this.http.delete(`${this.baseUri}/${id}`).pipe(tap());
    }

    lock(id: string, body: PlaceRequest): Observable<any> {
        return this.http.patch(`${this.baseUri}/${id}/lock`, body);
    }

    unlock(id: string, body: PlaceRequest): Observable<any> {
        return this.http.patch(`${this.baseUri}/${id}/unlock`, body);
    }

    getResidues(placeId: string): Observable<SelectListItem[]> {
        return this.http.get<SelectListItem[]>(`${this.baseUri}/residues/${placeId}`);
    }

    getDeliveryReport(req: PlaceDeliveryReportRequest): Observable<ResponseAPI<PlaceDeliveryReport>> {
        return this.http.post<ResponseAPI<PlaceDeliveryReport>>(`${this.baseUri}/deliveryReport`, req);
    }
}
