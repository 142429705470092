import { Inject, Injectable } from "@angular/core";
import { tap } from "rxjs";
import { TenantConfiguration } from "../shared/interfaces/tenant/tenantConfiguration.interface";
import { WINDOW } from "../shared/tokens/window.token";
import { TenantService } from "./tenant/tenant.service";

@Injectable({
    providedIn: 'root'
})
export class InitService {
    tenantConfigurations!: TenantConfiguration;

    constructor(@Inject(WINDOW) private window: Window, private tenantConfigService: TenantService) { }

    init() {
        let host = this.window.location.host;
        return this.tenantConfigService.getTenantConfigurationsByDomain(host).pipe(tap((config) => (this.tenantConfigurations = config)));
    }
}